import React, { Component } from 'react';

const TrustBox = ({ trustBoxRef }) => (
  <div
    ref={trustBoxRef} // We need a reference to this element to load the TrustBox in componentDidMount.
    className="trustpilot-widget" // Renamed this to className.
    data-locale="it-IT"
    data-template-id="5419b6a8b0d04a076446a9ad"
    data-businessunit-id="61af5075781e43aaadbbefad"
    data-style-height="24px"
    data-style-width="100%"
    data-min-review-count="10"
    data-style-alignment="center"
  >
    <a
      href="https://it.trustpilot.com/review/mylondoncorner.com"
      target="_blank"
      rel="noopener noreferrer"
    >
      Trustpilot
    </a>
  </div>
);

class TrustBoxContainer extends Component {
  constructor(props) {
    super(props);
    this.trustBoxRef = React.createRef();
  }

  componentDidMount() {
    // If window.Trustpilot is available, it means the TrustBox script is loaded.
    if (window.Trustpilot) {
      window.Trustpilot.loadFromElement(this.trustBoxRef.current, true);
    }
  }

  render() {
    return <TrustBox trustBoxRef={this.trustBoxRef} />;
  }
}

export default TrustBoxContainer;
