import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useAuth } from '../components/AuthContext';
import { useNavigate, useParams } from 'react-router-dom';

import Footer from "../components/Footer";
import Navbar from "../components/navbar/Navbar";

export default function Search() {
  const { user } = useAuth();
  
  const navigate = useNavigate();

  const { string } = useParams(); // Get the slug from URL parameters

  const [userData, setUserData] = useState({});
  const [loadingUserData, setLoadingUserData] = useState({});

  const [searchData, setSearchData] = useState([]);
  const [loadingSearchData, setLoadingSearchData] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      if (user && user.uid) {
        try {
          setLoadingUserData(true);
          const response = await axios.get(`https://api.mylondoncorner.com/user/${user.uid}`, {
            headers: {
              'X-API-KEY': process.env.REACT_APP_API_KEY
            }
          });
          setUserData(response.data.user);
          setLoadingUserData(false);
        } catch (error) {
          console.error('Error fetching data:', error);
          setLoadingUserData(false);
        }
      } else {
        console.log("User is not authenticated or user data is not available.");
      }
    };

    fetchData();
  }, [user]);

  useEffect(() => {
    setLoadingSearchData(true);
    // if(!loadingUserData) { if used unlogged users cant search
      axios.post(`https://api.mylondoncorner.com/search/${string}`, { user: userData }, {
        headers: {
          'X-API-KEY': process.env.REACT_APP_API_KEY // Include the API key in the request headers
        }
      })
      .then((response) => {
        setSearchData(response.data);
        setLoadingSearchData(false);
      })
      .catch((error) => {
        console.error('Error fetching data:', error);
        setLoadingSearchData(false);
      });
    // }
  }, [string, loadingUserData]);

  return (
    <>
      <Navbar />
      <br /><br />
      <div className='tickets-container'>
        <p style={{ fontWeight: 'bold' }}>Ecco i risultati della tua</p>
        <h1>Ricerca</h1>
        <br />
        {loadingSearchData ? (
          <div className="loading-animation"></div>
        ) : (
          <>
            <div className='tickets-display-large'>
              {searchData.map((result, index) => {
                if (index % 4 === 0) {
                  return (
                    <div className='row' key={index}>
                      {searchData
                        .filter(item => item.visible === true)
                        .slice(index, index + 4)
                        .map((result, innerIndex) => (
                          <div className='ticket-column' onClick={() => window.location.href = result.external_link ? result.external_link : result.departure_date ? '/trip/' + result.slug : result.purchasable ? '/tour/' + result.slug : '/post/' + result.slug} key={innerIndex}>
                            <img src={'https://api.mylondoncorner.com/' + (result.image_path ? result.image_path : result.images_paths[0])} style={{ cursor: 'pointer', width: '100%', borderRadius: '10px', border: 'solid 1px #ccc' }} alt="Image" />
                            {result.price >= 0 ?
                              result.departure_date ? 
                                <p style={{ textAlign: 'left', marginTop: '3%', fontSize: '15px' }}>Viaggio di gruppo</p>
                                :
                                <p style={{ textAlign: 'left', marginTop: '3%', fontSize: '15px' }}>Adatto a tutte le età • {result.duration}</p>
                              : 
                              <p style={{ textAlign: 'left', marginTop: '3%', fontSize: '15px' }}>Articolo • {result.clicks} clicks</p>
                            }
                            <h4 style={{ textAlign: 'left', marginTop: '2%' }}>{result.name ? result.name : result.title}</h4>
                            {result.price >= 0 && 
                              <p style={{ textAlign: 'left', marginTop: '2%', fontSize: '15px' }}>{ result.price_child != 0 ? 'Da £' + result.price_child + ' / persona' : result.price != 0 ? 'Da £' + result.price + ' / persona' : result.onRequest ? 'Da £' + result.price_private : 'GRATIS' }</p>                            
                            }
                          </div>
                        ))}
                    </div>
                  );
                }
                return null;
              })}
            </div>
            <div className='tickets-display-small'>
              {searchData.map((result, index) => {
                if (index % 2 === 0) {
                  return (
                    <div className='row' key={index}>
                      {searchData
                        .filter(item => item.visible === true)
                        .slice(index, index + 2)
                        .map((result, innerIndex) => (
                          <div className='ticket-column' onClick={() => window.location.href = result.external_link ? result.external_link : result.departure_date ? '/trip/' + result.slug : result.purchasable ? '/tour/' + result.slug : '/post/' + result.slug} key={innerIndex}>
                            <img src={'https://api.mylondoncorner.com/' + (result.image_path ? result.image_path : result.images_paths[0])} style={{ cursor: 'pointer', width: '100%', borderRadius: '10px', border: 'solid 1px #ccc' }} alt="Image" />
                            {result.price >= 0 ?
                              result.departure_date ? 
                                <p style={{ textAlign: 'left', marginTop: '3%', fontSize: '15px' }}>Viaggio di gruppo</p>
                                :
                                <p style={{ textAlign: 'left', marginTop: '3%', fontSize: '15px' }}>Adatto a tutte le età • {result.duration}</p>
                              : 
                              <p style={{ textAlign: 'left', marginTop: '3%', fontSize: '15px' }}>Articolo • {result.clicks} clicks</p>
                            }
                            <h4 style={{ textAlign: 'left', marginTop: '2%' }}>{result.name ? result.name : result.title}</h4>
                            {result.price >= 0 && 
                              <p style={{ textAlign: 'left', marginTop: '2%', fontSize: '15px' }}>{ result.price_child != 0 ? 'Da £' + result.price_child + ' / persona' : result.price != 0 ? 'Da £' + result.price + ' / persona' : 'GRATIS' }</p>                            
                            }
                          </div>
                        ))}
                    </div>
                  );
                }
                return null;
              })}
            </div>
          </>  
        )}
      </div>
      <Footer />
    </>
  )
}