import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../components/AuthContext';
import { FaSearch } from "react-icons/fa";

// Styles
import '../../styles/navbar/home-navbar-search-big-screen.css';


const NavbarSearchBigScreen = () => {
  const { user } = useAuth();

  const navigate = useNavigate();

  const [userData, setUserData] = useState([]);
  const [loadingUserData, setLoadingUserData] = useState("");

  const [searchData, setSearchData] = useState([]);
  const [searchString, setSearchString] = useState("");

  useEffect(() => {
    const fetchData = async () => {
      if (user && user.uid) {
        try {
          setLoadingUserData(true);
          const response = await axios.get(`https://api.mylondoncorner.com/user/${user.uid}`, {
            headers: {
              'X-API-KEY': process.env.REACT_APP_API_KEY
            }
          });
          setUserData(response.data.user);
          setLoadingUserData(false);
        } catch (error) {
          console.error('Error fetching data:', error);
          setLoadingUserData(false);
        }
      }
    };

    fetchData();
  }, [user]);

  const handleChange = async (event) => {
    const value = event.target.value;
    setSearchString(value);
    if (value) {
      try {
        const response = await axios.post(`https://api.mylondoncorner.com/search/${value}`, { user: userData }, {
          headers: {
            'X-API-KEY': process.env.REACT_APP_API_KEY
          }
        });
        setSearchData(response.data);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    } else {
      setSearchData([]);
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    navigate(`/search/${searchString}`);
    setSearchString('');
  };

  const handleItemClick = (item) => {
    if (item.title) {
      navigate(`/post/${item.slug}`);
    } else if (item.purchasable) {
      navigate(`/tour/${item.slug}`);
    } else if (item.hotel_stars) {
      navigate(`/trip/${item.slug}`);
    } else if (item.external_link) {
      window.open(item.external_link, '_blank');
    }
  };

  return (
    <div className="home-nav-search-widget-container">
      <form onSubmit={handleSubmit} className="home-nav-search-widget">
        <input
          type="text"
          id="search"
          name="search"
          placeholder="Cosa stai cercando?"
          required
          autoComplete="off"
          value={searchString}
          onChange={handleChange}
        />
        <label htmlFor="submit-form" onClick={handleSubmit}>
          <FaSearch className='home-nav-search-icon'></FaSearch>
        </label>
        <input type="submit" id="submit-form" style={{ display: 'none' }} />
      </form>
      {searchString && searchData.length > 0 && (
        <div className="home-nav-search-dropdown">
          {searchData.map((item, index) => (
            <div key={index} className="home-nav-search-dropdown-item" onClick={() => handleItemClick(item)}>
              {item.departure_date ? item.name + ' (Viaggio di gruppo)': item.title ? item.title : item.name}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default NavbarSearchBigScreen;
