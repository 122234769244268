import React from "react";
import Image from "./Image";
import TrustBox from "./widgets/TrustBox";
import { useNavigate } from 'react-router-dom';
import { signOut } from 'firebase/auth';

import { logoInstagram, logoFacebook, logoTiktok, logoYoutube } from 'ionicons/icons';
import { IonIcon } from '@ionic/react';

import { auth } from '../config/firebase';
import { useAuth } from '../components/AuthContext';
import '../styles/footer.css';

function Footer() {
  const { user } = useAuth();
  const navigate = useNavigate();

  const logout = async () => {
    try {
      await signOut(auth);
      navigate('/');
    } catch (err) {
      console.error(err);
    };
  };

  return (
    <>
      <br /><br />
      <div className="footer">
        <br />
        <br />
        <Image url="https://api.mylondoncorner.com/images/logo-black.png" onClick={() => navigate('/')} width='60' alt="logo" />
        <br />
        <TrustBox />
        <br />
        <div className="footer-followUsOn">
          <a href="https://www.instagram.com/mylondoncorner/"><IonIcon icon={logoInstagram} /></a>
          <a href="https://www.facebook.com/mylondoncornerpage"><IonIcon icon={logoFacebook} /></a>
          <a href="https://www.tiktok.com/@mylondoncorner"><IonIcon icon={logoTiktok} /></a>
          <a href="https://www.youtube.com/@mylondoncorner"><IonIcon icon={logoYoutube} /></a>
          <br />
          <br />
          <br /><br />
        </div>
        <div className="row">
          <div className="column-footer">
            <h3>Contatti</h3>
            <br />
            <p>
              <a href="mailto:info@mylondoncorner.com">
                info@mylondoncorner.com
              </a>
            </p>
            <br />
            <p>
              <a href="/">
                +44 (0) 7853545887
              </a>
            </p>
            <br />
            <p>
              <a href="/">
                Londra, Regno Unito
              </a>
            </p>
          </div>
          <div className="column-footer">
            <h3>Link Rapidi</h3>
            <br />
            <p><a href="/">Home</a></p><br />
            <p><a href="/contact">Contattaci</a></p><br />
            <p><a href="/legal/privacy-policy">Privacy policy</a></p><br />
            <p><a href="/legal/terms-and-conditions">Termini e condizioni</a></p><br />
            {user ? (
              <p><a href="" onClick={logout}>{user.email ? user.email : user.isAnonymous ? 'Guest' : null } (Logout)</a></p>
            ) : (
              <p><a href="/login"> Login </a></p>
            )}
          </div>
          <div className="column-footer">
            <h3>Partnerships</h3>
            <br />
            <p><a href="/contact">Contattaci ora</a></p>
          </div>
        </div>
        <p style={{ textAlign: 'center', color: 'black', fontSize: 'small', padding: '10px', padding: '3%' }}>mylondoncorner è registrato in Inghilterra con numero di registrazione 14771046. Ufficio registrato: Suite 205 Balfour House, 741 High Road, London, United Kingdom, N12 0BP.</p>
      </div>
      <div className="footer-line">
        2024 © <a href="https://www.instagram.com/mylondoncorner/">mylondoncorner</a> | <a href="https://instagram.com/alessandrorotondo_">Created by Alessandro Rotondo</a>
      </div>
    </>
  );
}

export default Footer;
