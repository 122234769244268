import React, { useRef, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { useNavigate } from 'react-router-dom';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/free-mode';
import 'swiper/css/pagination';

// import required modules
import { FreeMode, Pagination } from 'swiper/modules';

import '../../styles/components/SliderTours.css';

export default function App({ images }) {
  const navigate = useNavigate();

  return (
    <>
      <Swiper
        breakpoints={{
          // when window width is >= 320px
          320: {
            slidesPerView: 1.5,
            spaceBetween: 20,
          },
          // when window width is >= 640px
          640: {
            slidesPerView: 2.5,
            spaceBetween: 30,
          },
          // when window width is >= 1024px
          1024: {
            slidesPerView: 3.5,
            spaceBetween: 30,
          },
        }}
        freeMode={true}
        pagination={{
          clickable: true,
        }}
        modules={[FreeMode, Pagination]}
      >
        {images.filter(item => item.visible === true).map((item) => (
          <SwiperSlide key={item._id} onClick={() => item.purchasable ? window.location.href = '/tour/' + item.slug : window.location.href = item.external_link}>
            <div className='slider-tours-wrapper'>
              <div className='slider-tour-column-image-container'>
                <img src={'https://api.mylondoncorner.com/' + item.images_paths[0]} style={{ cursor: 'pointer', width: '100%', borderRadius: '10px', border: 'solid 1px #ccc' }} alt="Image" />
              </div>
              <p className="slider-tours-p">Adatto a tutte le età • {item.duration}</p>
              <h4 className="slider-tours-h4">{item.name}</h4>
              <p className="slider-tours-p">{item.car_base_price ? 'Da £' + item.car_base_price + ' / macchina' :
                    item.price_child != 0 ? 'Da £' + item.price_child + ' / persona' :
                    item.price != 0 ? 'Da £' + item.price + ' / persona' : item.onRequest ? 'Da £' + item.price_private : 'GRATIS'}</p>
                    <br />
              <a href={process.env.REACT_APP_BASE_URL + `/tour/${item.slug}`}><button className='button'>Prenota ora</button></a>
              <br/><br/>
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
    </>
  );
}
